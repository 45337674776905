import React, { Component } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default class Confirmation extends Component {
    render() {
        return (
            <div>
                <p>Thanks for submitting feedback! We really appreciate it</p>
                <p>We'll cover it at the next eboard meeting (hopefully)</p>
                <Link
                to='/feedback'
                className='secBarLink'
                state={this.state}>
                    Back to Feedback
                </Link>
            </div>
        );
    }
}