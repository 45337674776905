import React, { Component } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { blankCheck } from '../../Errors';
import { USERID } from '../../App.js';
import { authRole } from '../../../index.js';

export default class SubmitFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.state,
      Title: "",
      Date: "",
      Content: "",
      Error: false,
      Submit: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onTitleChange = (event) => {
    this.setState({Title: event.target.value})
  }

  onDateChange = (event) => {
    this.setState({Date: event.target.value})
  }

  onContentChange = (event) => {
    this.setState({Content: event.target.value})
  }

  errorCheck = () => {
    if (blankCheck(this.state.Title)) {
      console.error("Blank field: Title")
      alert("Blank field: Title")
      return false;
    }
    if(blankCheck(this.state.Content)) {
      console.error("Blank field: Content")
      alert("Blank field: Content")
      return false;
    }
    return true;
  }

  getDate = () => {
    let date = new Date();
    return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`
  }

  submitData = () => {
    if(this.errorCheck()) {
      this.setState({Error: false})
      const feedback = {
        Title: this.state.Title,
        Date: this.getDate(),
        Content: this.state.Content,
      }
      var url = this.state.constants.hostname + '/api/feedback/addFeedback';
      fetch(url, {
        body: JSON.stringify({ feedback: feedback }),
        headers: {
          'Authorization':`${localStorage.getItem(USERID)}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
      .then(res => {
        if (!res.ok) {
          this.setState({Error: true})
          return false;
        }
        else {
          this.setState({Title: "", Content: "", Submit: true})
        }
      })
      .catch(error => { console.error(error); })
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.submitData()
  }

  render() {
    return (
      <div>
        {this.state.Submit?
          <Redirect to={`/feedback/confirmation`} />
          :
          null
        }
        {this.state.Error?
          <h2>The following elements could not be added. Contact the webmaster immediately</h2>
          :
          null
        }
        <h2>Submit Eboard Feedback</h2>
        <div className='secBarNav'>
          { authRole(this.props.state.userRole, this.state.constants.roles.president)
            || authRole(this.props.state.userRole, this.state.constants.roles.secretary)
            || authRole(this.props.state.userRole, this.state.constants.roles.conductor)
            || authRole(this.props.state.userRole, this.state.constants.roles.vicePresident)
            || authRole(this.props.state.userRole, this.state.constants.roles.treasurer)
            || authRole(this.props.state.userRole, this.state.constants.roles.sectionLeader)?
            <Link
                to='/feedback/view'
                className='secBarLink'
                state={this.state}
            >
                <div className='oneline'>
                <p className='secBarText'>
                    View Feedback
                </p>
                </div>
                <br/>
            </Link>
            :
            null
          }
        </div>
        <hr />
        <p>Feel free to submit any feedback you have for the Eboard!</p>
        <p>This form is completely anonymous, so feel free to speak your mind</p>
        <p>It can be things we can improve on, or things you want to see more of</p>
        <p>Please only submit serious feedback, we take everything submitted here very seriously</p>
        <form className='userForm'>
          <table>
            <tbody>
              <tr>
                <td>
                  <label className='secretaryLabel'>
                    Title:
                  </label>
                </td>
                <td>
                  <input
                    type="text"
                    name="Title"
                    onChange={this.onTitleChange}
                    value={this.state.Title}
                    className="qdbInput"
                    maxLength="100"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label className='secretaryLabel'>
                    Feedback:
                  </label>
                </td>
                <td>
                  <textarea
                    type="text"
                    name="FirstName"
                    onChange={this.onContentChange}
                    value={this.state.Content}
                    className="quoteTextArea"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </form>
        <button
          onClick={this.handleSubmit}
          className='secretarySubmit'
        >
          Submit Feedback
        </button>
      </div>
    );
  }
}
