import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import "../../../css/qdb.css";
import { USERID } from '../../App.js';

export default class ViewFeedback extends Component {
  constructor(props) {
    super(props)
    this.state={...this.props.state, feedback:[], filteredFeedback: []};
  }

  componentDidMount() {
    var url = this.state.constants.hostname + '/api/feedback/getFeedback';
    fetch(url, {
      headers: {'Authorization':`${localStorage.getItem(USERID)}`}
    })
    .then(res => {
      if (!res.ok) {
        return false;
      }
      return res.json();
    })
    .then(data => {
      if (!data) { return; }
      this.setState({ feedback : data, filteredFeedback: data });
    })
    .catch(error => { console.error(error); });
  }

  handleSearch = (event) => {
    var filteredFeed = [];
    var searchVal = event.target.value.toLowerCase();
    this.state.feedback.filter(feed => {
      if (feed.Title.toLowerCase().includes(searchVal)
        || feed.Content.toLowerCase().includes(searchVal)
      ) {
        filteredFeed.push(feed);
      }
      return filteredFeed;
    })
    this.setState({filteredFeedback: filteredFeed});
  }

  render() {
    const roles = this.props.state.constants.roles;
      return (
        <div id="Qdb">
          <h2>Feedback</h2>
            <div className='oneline'>
              <Link
                to='/feedback'
                className='secBarLink'
                state={this.state}
              >
                <div className='oneline'>
                  <p className='secBarText'>
                    Back to Feedback
                  </p>
                </div>
              </Link>
            </div>
          <hr/>
          <br/>
          <div className='oneline center'>
            <p className='searchTag'><b>Search:</b></p>
            <input
              onChange={this.handleSearch}
              className='quoteSearch'
              placeholder="Search for Feedback..."
            />
          </div>
          <div className="qdbTables">
            {this.state.filteredFeedback.map(feed =>
              <div id={feed.FID} key={feed.FID}>
                <table className="QDBTable">
                  <tbody>
                    <tr className="QDBTitle">
                      <th colSpan='2'>{feed.Title}</th>
                    </tr>
                    <tr className="QDBRow quoteRow">
                      <td colSpan='2' className='quote multiline'>
                        <ReactMarkdown source={feed.Content} />
                      </td>
                    </tr>
                    <tr className="QDBRow secondary">
                      <td className='quotePerson'>-{feed.Date}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      );
    }
}
