import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter } from 'react-router-dom';
import App from './components/App';
import { Logout } from './components/Logout.js';
import './css/index.css';
import * as serviceWorker from './serviceWorker';
import { config } from './config.js';

export var firebase = require('firebase/app');
require('firebase/auth');

var port = `:${process.env.REACT_APP_PORT || 3030}`;
if (port === ':Heroku') {
  port = '';
}

var hostname = `//${window.location.hostname}${port}`;

var initialState = {
  constants: {
    roles: {
      admin: 'admin',
      president: 'president',
      conductor: 'conductor',
      secretary: 'secretary',
      vicePresident: 'vicePresident',
      treasurer: 'treasurer',
      member: 'member',
      nonMember: 'nonMember',
      alumni: 'alumni',
      sectionLeader: 'sectionLeader',
    },
    hostname
  },
  userRole: null,
  user: null
};

export function authRole(currentRole, target) {
  const { roles } = initialState.constants;
  if (currentRole === target) {
    return true;
  }
  switch (currentRole) {
    case roles.admin:
      return authRole(roles.president, target);
    case roles.president:
      return authRole(roles.secretary, target) 
        || authRole(roles.conductor, target)
	|| authRole(roles.treasurer, target)
	|| authRole(roles.vicePresident, target)
        || authRole(roles.sectionLeader, target);
    case roles.secretary:
      return authRole(roles.member, target);
    case roles.conductor:
      return authRole(roles.member, target);
    case roles.treasurer:
      return authRole(roles.member, target);
    case roles.vicePresident:
      return authRole(roles.member, target);
    case roles.sectionLeader:
      return authRole(roles.member, target);
    case roles.member:
      return authRole(roles.alumni, target);
    default:
      return false;
  }
}

firebase.initializeApp(config);

const render = () => {
  ReactDOM.render(
    <BrowserRouter>
      <App state={initialState}/>
      <Route path='/logout' component={Logout}/>
    </BrowserRouter>,
    document.getElementById('root')
  );
  console.log('Rendered the Application');
};

render();


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
