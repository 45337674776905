import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { authRole } from "../../index.js";

import ViewFeedback from './FeedbackPages/ViewFeedback.js';
import SubmitFeedback from './FeedbackPages/SubmitFeedback.js';
import Confirmation from './FeedbackPages/Confirmation.js';

export class Feedback extends Component {
  constructor(props) {
    super(props)
    this.state=this.props.state;
  }

  render() {
    return (
      <div id="Feedback">
        <Switch>
          <Route path='/feedback/submit' component={() => <SubmitFeedback state={this.state} />}/>
          <Redirect exact from='/feedback' to='/feedback/submit'/>
          <Route path='/feedback/confirmation' component={() => <Confirmation state={this.state}/>}/>
          {authRole(this.props.state.userRole, this.state.constants.roles.president)
            || authRole(this.props.state.userRole, this.state.constants.roles.secretary)
            || authRole(this.props.state.userRole, this.state.constants.roles.conductor)
            || authRole(this.props.state.userRole, this.state.constants.roles.vicePresident)
            || authRole(this.props.state.userRole, this.state.constants.roles.treasurer)
            || authRole(this.props.state.userRole, this.state.constants.roles.sectionLeader)?
            <Route path='/feedback/view' component={() => <ViewFeedback state={this.state} />}/>
            :
            <Redirect to='/404' />
          }
          <Redirect to='/404' />
        </Switch>
      </div>
    );
  }
}
