import React, { Component } from 'react';
import '../../css/conductor.css'
import { USERID } from '../App.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom/cjs/react-router-dom.min.js';

export class Conductor extends Component {
  constructor(props) {
    super(props)
    this.state = {...this.props.state, songs:[], filteredSongs: [] };
  }

  componentDidMount() {
    var url = this.state.constants.hostname + '/api/music';
    fetch(url, {
      headers: {'Authorization':`${localStorage.getItem(USERID)}`}
    })
      .then(res => {
        if (!res.ok) {
          return false;
        }
        return res.json();
      })
      .then(data => {
        if (!data) {
          return;
        }
        this.setState({ songs : data, filteredSongs: data });
      })
      .catch(error => {
        console.error(error);
      });
  }

  statusAll = (status) => {
    var url = this.state.constants.hostname + '/api/music/statusAll';
    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type':'application/json',
        'Authorization':`${localStorage.getItem(USERID)}`
      },
      body: JSON.stringify({status: status})
    })
    .then(res => {
      if (!res.ok) {
        return false;
      }
      return res.text();
    })
    .catch(error => {
      console.error(error);
    });
    alert('All songs set to ' + status);
    var updatedSongs = this.state.songs;
    updatedSongs.forEach((item, index, arr) => {
      item.Status = status;
    });
    this.setState({songs: updatedSongs});
  }

  handleSearch = (event) => {
    var filteredSongs = [];
    var idx = 0;
    var searchVal = event.target.value.toLowerCase();
    this.state.songs.filter(song => {
      if (song.Title.toLowerCase().includes(searchVal)) {
        filteredSongs[idx] = song;
        idx++;
      }
      return filteredSongs;
    })
    this.setState({filteredSongs: filteredSongs});
  }

  render() {
    return (
      <div id="conductor">
        <h2 className="conductorTitle">Conductor</h2>
          <div className="conductorDiv">
            <table className="statusAllTable">
              <tbody>
                <tr>
                  <td>
                    <input type="button" className="songStatusButton statusAll allPublic" value="All Public"
                    onClick={() => {
                      if (window.confirm('Are you sure you want to make all songs public?')) {
                        this.statusAll('Public');
                      }
                    }}/>
                  </td>
                  <td>
                    <input
                      onChange={this.handleSearch}
                      className='songSearch'
                      placeholder="Search for songs..."
                    />
                  </td>
                  <td>
                    <input type="button" className="songStatusButton statusAll allPrivate" value="All Private"
                    onClick={() => {
                      if (window.confirm('Are you sure you want to make all songs private?')) {
                        this.statusAll('Private');
                      }
                    }}/>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="conductorTable">
              <thead>
                <tr className="conductorRow">
                  <th className="conductorHeader">Song</th>
                  <th className='conductorHeader'>mp3?</th>
                  <th className="conductorHeader">Status</th>
                  <th className="conductorHeader">Public Comments</th>
                  <th className="conductorHeader">Private Comments</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.filteredSongs.map(song =>
                    <ConductorEntry key={song.Slug} state={this.state} song={song} />
                  )
                }
              </tbody>
            </table>
        </div>
      </div>
    );
  }
}

export class ConductorEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.state,
      song: this.props.song,
      initalPubComment: this.props.song.PublicComment,
      initalPriComment: this.props.song.PrivateComment,
      needPubSave: false,
      needPrivSave: false
    };
  }

  pubCommentChange = (event) => {
    let tempSong = this.state.song;
    tempSong.PublicComment = event.target.value;
    if (this.state.initalPublicComment !== tempSong.PublicComment) {
      this.setState({song: tempSong, needPubSave: true})
    } else {
      this.setState({song: tempSong, needPubSave: false})
    }
  }

  savePubComment = () => {
    var url = this.state.constants.hostname + '/api/music/applyPublicComment';
    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type':'application/json',
        'Authorization':`${localStorage.getItem(USERID)}`
      },
      body: JSON.stringify({song: this.state.song})
    })
    .then(res => {
      if (!res.ok) {
        return false;
      }
      this.setState({needPubSave: false})
    })
    .catch(error => {
      console.error(error);
    });
  }

  privCommentChange = (event) => {
    let tempSong = this.state.song;
    tempSong.PrivateComment = event.target.value;
    if (this.state.initalPriComment !== tempSong.PrivateComment) {
      this.setState({song: tempSong, needPrivSave: true})
    } else {
      this.setState({song: tempSong, needPrivSave: false})
    }
  }

  savePrivComment = () => {
    var url = this.state.constants.hostname + '/api/music/applyPrivateComment';
    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type':'application/json',
        'Authorization':`${localStorage.getItem(USERID)}`
      },
      body: JSON.stringify({song: this.state.song})
    })
    .then(res => {
      if (!res.ok) {
        return false;
      }
      this.setState({needPrivSave: false})
    })
    .catch(error => {
      console.error(error);
    });
  }

  toggleStatus = () => {
    var url = this.state.constants.hostname + '/api/music/toggleStatus';
    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type':'application/json',
        'Authorization':`${localStorage.getItem(USERID)}`
      },
      body: JSON.stringify({song: this.props.song})
    })
    .then(res => {
      if (!res.ok) {
        return false;
      }
      return res.text();
    })
    .then(data => {
      if (!data) {
        return;
      }
      var changedSong = this.props.song;
      changedSong.Status = data;
      this.setState({song: changedSong});
    })
    .catch(error => {
      console.error(error);
    });
  }

  render () {
    return (
      <tr className='conductorRow'>
        <td className='conductorData'>
          <div key={this.props.song.Slug} className='songLink'>
          <Link
            to={"/music/" + this.props.song.Slug}
            className='songColor'
          >
            <FontAwesomeIcon icon="file-alt" className='faFile'/>
            {this.props.song.Slug}
          </Link>
          </div>
        </td>
        <td className='conductorData'>
          {this.state.song.mp3?
              <audio controls>
                <source
                  src={`${this.state.constants.hostname}/api/music/mp3?title=${this.state.song.Title}&user=${localStorage.getItem(USERID)}`}
                  type="audio/mpeg"
                ></source>
              Your browser does not support the audio element.
              </audio>
              :
              <p>None</p>
            }
        </td>
        <td className='conductorData'>
          <input type="button" className={'songStatusButton status' + this.state.song.Status} value={this.state.song.Status} onClick={this.toggleStatus}/>
        </td>
        <td className='conductorData'>
          <div className='oneline commentSave'>
            <input
              type='text'
              className='conductorInput'
              onChange={this.pubCommentChange}
              value={this.state.song.PublicComment}
              placeholder='Jimmy a comment in here..'
              maxLength="500"
            />
            {this.state.needPubSave?
              <FontAwesomeIcon
                icon="save"
                className='faSave'
                onClick={this.saveComment}
              />
              :
              null
            }
          </div>
        </td>
        <td className='conductorData'>
          <div className='oneline commentSave'>
            <input
              type='text'
              className='conductorInput'
              onChange={this.privCommentChange}
              value={this.state.song.PrivateComment}
              placeholder='Jimmie a comment in here..'
              maxLength="500"
            />
            {this.state.needPrivSave?
              <FontAwesomeIcon
                icon="save"
                className='faSave'
                onClick={this.savePrivComment}
              />
              :
              null
            }
          </div>
        </td>
      </tr>
    );
  }
}
